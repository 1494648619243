exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-material-[name]-tsx": () => import("./../../../src/pages/material/[name].tsx" /* webpackChunkName: "component---src-pages-material-[name]-tsx" */),
  "component---src-pages-material-sanity-material-slug-current-tsx": () => import("./../../../src/pages/material/{sanityMaterial.slug__current}.tsx" /* webpackChunkName: "component---src-pages-material-sanity-material-slug-current-tsx" */),
  "component---src-pages-material-tsx": () => import("./../../../src/pages/material.tsx" /* webpackChunkName: "component---src-pages-material-tsx" */),
  "component---src-pages-project-[name]-tsx": () => import("./../../../src/pages/project/[name].tsx" /* webpackChunkName: "component---src-pages-project-[name]-tsx" */),
  "component---src-pages-sanity-service-slug-current-tsx": () => import("./../../../src/pages/{sanityService.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-service-slug-current-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

